import { TabsContent } from "@/components/atoms/shadcn/ui/tabs";
import {
  WasteValidationsContainer,
  BarChartHorizontal,
  BarChartVertical,
  DoughnutChart,
  BarChartHorizontalStacked,
} from "@/components/molecules";
import { useCheckRefetch } from "@/hooks/useCheckRefetch";
import { useGraphicsData } from "@/hooks/useGraphicsData";
import { fetcherOverviewDemographicsProductRegistrations } from "@/pages/admins/products/overview";
import { useQuery } from "@tanstack/react-query";
import { Session } from "next-auth";
import { FilterPerYearController } from "../FilterPerYearController/FilterPerYearController";
import { generateGraphic } from "@/shared/functions/generateGraphic";

type ProductDemographicsProps = {
  session: Session | null;
  isActive?: boolean;
};

export const ProductDemographics = ({
  session,
  isActive,
}: ProductDemographicsProps) => {
  const { control, ranges, years, global } = useGraphicsData({
    keysRanges: [
      "genders",
      "ages",
      "states",
      "towns",
      "brands_per_gender",
      "brands_per_age",
      "sub_brands_per_gender",
      "sub_brands_per_age",
      "waste_category_per_gender",
      "waste_category_per_age",
      "wastes_per_gender",
      "wastes_per_age",
    ],
    keysYear: [""],
  });
  const { data: stats, refetch } = useQuery({
    queryKey: ["overviewDemographicsProductRegistrations"],
    enabled: session?.user?.token ? true && isActive : false,
    queryFn: async () => {
      return session?.user?.token
        ? await fetcherOverviewDemographicsProductRegistrations({
            token: session?.user?.token!,
            ranges,
            global,
            years,
          })
        : null;
    },
  });
  useCheckRefetch(refetch, global, ranges, years);
  return (
    <TabsContent value="Demographics">
      <div className="flex w-full  flex-wrap items-center justify-around px-5 py-3  gap-4">
        <div className="w-full flex justify-end px-5 items-center">
          <FilterPerYearController name={"globalRange"} control={control} />
        </div>
      </div>
      <div className="grid grid-cols-2 px-5 py-2 gap-4 my-14">
        <WasteValidationsContainer
          title="Género"
          subtitle="*Considerando usuarios que han registrado una vez"
          filter={
            <FilterPerYearController
              name={"graphsRanges.genders"}
              control={control}
            />
          }
        >
          <DoughnutChart
            data={generateGraphic(stats as any, "genders") as any}
            // data={undefined}
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Edad"
          subtitle="*Considerando usuarios que han registrado una vez"
          filter={
            <FilterPerYearController
              name={"graphsRanges.ages"}
              control={control}
            />
          }
        >
          <BarChartVertical
            data={generateGraphic(stats as any, "ages") as any}
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Ubicaciones de usuarios (TOP 10)"
          subtitle="*Estado"
          filter={
            <FilterPerYearController
              name={"graphsRanges.states"}
              control={control}
            />
          }
        >
          <BarChartHorizontal
            data={generateGraphic(stats as any, "states") as any}
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Ubicaciones de usuarios (TOP 10)"
          subtitle="*Municipio"
          filter={
            <FilterPerYearController
              name={"graphsRanges.towns"}
              control={control}
            />
          }
        >
          <BarChartHorizontal
            data={generateGraphic(stats as any, "towns") as any}
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Marcas más registradas por género (TOP 10)"
          subtitle="*Considerando usuarios que han registrado una vez"
          filter={
            <FilterPerYearController
              name={"graphsRanges.brands_per_gender"}
              control={control}
            />
          }
        >
          <BarChartHorizontalStacked
            data={
              generateGraphic(
                stats as any,
                "brands_per_gender",
                true,
                true
              ) as any
            }
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Marcas más registradas por edad (TOP 10)"
          subtitle="*Considerando usuarios que han registrado una vez"
          filter={
            <FilterPerYearController
              name={"graphsRanges.brands_per_age"}
              control={control}
            />
          }
        >
          <BarChartHorizontalStacked
            data={
              generateGraphic(stats as any, "brands_per_age", true, true) as any
            }
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Submarcas más registradas por género (TOP 10)"
          subtitle="*Considerando usuarios que han registrado una vez"
          filter={
            <FilterPerYearController
              name={"graphsRanges.sub_brands_per_gender"}
              control={control}
            />
          }
        >
          <BarChartHorizontalStacked
            data={
              generateGraphic(
                stats as any,
                "sub_brands_per_gender",
                true,
                true
              ) as any
            }
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Submarcas más registradas por edad (TOP 10)"
          subtitle="*Considerando usuarios que han registrado una vez"
          filter={
            <FilterPerYearController
              name={"graphsRanges.sub_brands_per_age"}
              control={control}
            />
          }
        >
          <BarChartHorizontalStacked
            data={
              generateGraphic(
                stats as any,
                "sub_brands_per_age",
                true,
                true
              ) as any
            }
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Categorías más registradas por género (TOP 10)"
          filter={
            <FilterPerYearController
              name={"graphsRanges.waste_category_per_gender"}
              control={control}
            />
          }
        >
          <BarChartHorizontalStacked
            data={
              generateGraphic(
                stats as any,
                "waste_category_per_gender",
                true,
                true
              ) as any
            }
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Categorías más registradas por edad (TOP 10)"
          filter={
            <FilterPerYearController
              name={"graphsRanges.waste_category_per_age"}
              control={control}
            />
          }
        >
          <BarChartHorizontalStacked
            data={
              generateGraphic(
                stats as any,
                "waste_category_per_age",
                true,
                true
              ) as any
            }
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Residuos más registradas por género (TOP 10)"
          filter={
            <FilterPerYearController
              name={"graphsRanges.wastes_per_gender"}
              control={control}
            />
          }
        >
          <BarChartHorizontalStacked
            data={
              generateGraphic(
                stats as any,
                "wastes_per_gender",
                true,
                true
              ) as any
            }
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Residuos más registradas por edad (TOP 10)"
          filter={
            <FilterPerYearController
              name={"graphsRanges.wastes_per_age"}
              control={control}
            />
          }
        >
          <BarChartHorizontalStacked
            data={
              generateGraphic(stats as any, "wastes_per_age", true, true) as any
            }
          />
        </WasteValidationsContainer>
      </div>
    </TabsContent>
  );
};
