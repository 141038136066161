import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/atoms/shadcn/ui/select";
import React, { ButtonHTMLAttributes, memo } from "react";

import { CampaignsOptions } from "@/constants/campaignsData";
import { cn } from "@/lib/utils";
import { VariantProps, cva } from "class-variance-authority";

export const selectWithDefaultVariants = cva("text-xxs ring-offset-slate-950", {
  variants: {
    variant: {
      default: "text-[#B4B4B4] placeholder:text-[#B4B4B4] ",
      adminGreen:
        "rounded-lg border border-admins-text-active placeholder:text-admins-text-active text-admins-text-active  focus:ring-offset-white",
      adminDisabled:
        "rounded-lg bg-[#F1F1F1] text-[#AAAAAA] placeholder:text-[#AAAAAA] border-none focus:ring-offset-white",
      ghost: "bg-transparent w-fit rounded-none border-none",
    },
  },
});

interface SelectWithDefaultProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof selectWithDefaultVariants> {
  value?: string;
  setValue: (value: string) => void;
  placeHolder: string;
  selectedItems: CampaignsOptions[];
}

export const SelectWithDefault = ({
  setValue,
  value = "default",
  selectedItems,
  className,
  variant,
  placeHolder = "",
  disabled = false,
}: SelectWithDefaultProps) => {
  return (
    <Select
      onValueChange={(value) => {
        setValue(value !== "default" ? value : "");
      }}
      defaultValue={value}
      disabled={disabled}
    >
      <SelectTrigger
        className={cn(selectWithDefaultVariants({ variant, className }))}
      >
        <SelectValue
          className="placeholder:text-[#B4B4B4]"
          style={{
            fontSize: "0.8rem",
          }}
          placeholder={placeHolder}
        />
      </SelectTrigger>
      <SelectContent>
        <SelectItem key={`default_value_select_item_key`} value={"default"}>
          Selecciona una opción...
        </SelectItem>
        {selectedItems.map((uni_option, idx) => (
          <CustomItem
            key={`${uni_option.name}_key_${idx * Math.random()}`}
            value={uni_option.id}
            style={{
              cursor: "pointer",
            }}
          >
            {uni_option.name}
          </CustomItem>
        ))}
      </SelectContent>
    </Select>
  );
};

const CustomItem = memo(SelectItem);
