import { TabsContent } from "@/components/atoms/shadcn/ui/tabs";
import {
  CardGradientData,
  CardNumOverview,
  WasteValidationsContainer,
} from "@/components/molecules";
import { useCheckRefetch } from "@/hooks/useCheckRefetch";
import { useGraphicsData } from "@/hooks/useGraphicsData";
import { generateGraphic } from "@/shared/functions/generateGraphic";
import { useQuery } from "@tanstack/react-query";
import { Session } from "next-auth";
import { FilterPerYearController } from "../FilterPerYearController/FilterPerYearController";
import { dataGraphicsType, dataPerCollectionCenterType } from "@/interfaces";
import { Controller } from "react-hook-form";
import { CheckBoxGroup } from "../CheckBoxGroup/CheckBoxGroup";
import { OverviewItem } from "../OverviewItem/OverviewItem";
import { useTypeCollectionCenters } from "@/hooks/useTypeCollectionCenters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPeopleGroup, faStar } from "@fortawesome/free-solid-svg-icons";
import { Dot } from "lucide-react";
import { Separator } from "@/components/atoms/shadcn/ui/separator";
import { fetcherOverviewDataCollectionCentersRegistrations } from "@/pages/admins/collectioncenters/overview";
import { dataServices } from "@/hooks";
import { formatParamsForFilters } from "@/shared/functions/formData";

type ProductActivityProps = {
  session: Session | null;
  isActive: boolean;
};

export const CollectionCenterActivity = ({
  session,
  isActive,
}: ProductActivityProps) => {
  const { control, ranges, years, global } = useGraphicsData({
    keysRanges: [
      "location_state",
      "location_town",
      "top_centers",
      "percents",
      "categories",
      "waste",
      "categories_minor",
      "waste_minor",
      "centers_categories",
      "centers_wastes",
    ],
    keysYear: ["year_filter"],
  });

  const {
    control: control_gp,
    ranges: ranges_gp,
    years: years_gp,
    global: global_gp,
  } = useGraphicsData({
    keysRanges: [
      "location_state",
      "location_town",
      "top_centers",
      "percents",
      "categories",
      "waste",
      "categories_minor",
      "waste_minor",
      "centers_categories",
      "centers_wastes",
    ],
    keysYear: ["year_filter"],
  });

  const { type_collection_center, control_centers } = useTypeCollectionCenters({
    default_type_collection_center: "global",
  });

  const {
    data: stats_collection_centers,
    refetch: refetch_collection_centers,
  } = useQuery({
    queryKey: ["overview_collection_centers", type_collection_center],
    enabled: session?.user?.token && isActive ? true : false,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      return session?.user?.token
        ? await fetcherOverviewDataCollectionCentersRegistrations({
            token: session?.user?.token!,
            ranges: ranges,
            global: global,
            years: years,
            type_collection_center,
          })
        : null;
    },
  });
  {
    /* Datos de analisis global */
  }

  const { data: stats_collectors, refetch: refetch_collectors } = useQuery({
    queryKey: ["overview_global_complement"],
    enabled: session?.user?.token ? true : false,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      return session?.user?.token
        ? await fetcherOverviewDataCollectionCentersRegistrations({
            token: session?.user?.token!,
            ranges: ranges_gp,
            global: global_gp,
            years: years_gp,
            type_collection_center: "green_points",
          })
        : null;
    },
  });

  const { data: cards, refetch: refetchCards } = useQuery({
    queryKey: ["cards_overview"],
    enabled: session?.user?.token ? true : false,
    queryFn: async () => {
      return session?.user?.token ? await fetchCards() : { data: {} };
    },
  });

  async function fetchCards() {
    const { localRanges, localYears } = formatParamsForFilters(
      ranges,
      global,
      years
    );
    const response = await dataServices({
      url: "/collection_centers/cards_overview",
      token: session?.user?.token!,
      params: {
        collection_center_types:
          type_collection_center === "global"
            ? ["centro_acopio", "punto_verde"].join(",")
            : type_collection_center === "collection_centers"
            ? ["centro_acopio"].join(",")
            : type_collection_center === "green_points"
            ? ["punto_verde"].join(",")
            : ["recolector"],
        ...localRanges,
        ...localYears,
      },
    });
    return response;
  }

  const dataGraphics_1: dataGraphicsType = [
    {
      title: "Historial de validaciones por tipo de centro",
      subtitle: undefined,
      nameFilter: "graphsYear.year_filter",
      span: 2,
      graphicType: "line",
      name_graphic_data: "validation_center_types",
    },
  ];
  const dataPerCollectionCenter: dataPerCollectionCenterType = {
    global: [
      {
        title: "Ubicaciones con más validaciones (TOP 10)",
        subtitle: "*Estado",
        nameFilter: "graphsRanges.location_state",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "states",
        variant: "collection_centers",
      },
      {
        title: "Ubicaciones con más validaciones (TOP 10)",
        subtitle: "*Estado",
        nameFilter: "graphsRanges.location_state",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "states",
        variant: "green_points",
      },
      {
        title: "Ubicaciones con más validaciones (TOP 10)",
        subtitle: "*Municipio",
        nameFilter: "graphsRanges.location_town",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "towns",
        variant: "collection_centers",
      },
      {
        title: "Ubicaciones con más validaciones (TOP 10)",
        subtitle: "*Municipio",
        nameFilter: "graphsRanges.location_town",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "towns",
        variant: "green_points",
      },
      {
        title: "Centros de Acopio con más Validaciones (TOP 10) ",
        subtitle: undefined,
        nameFilter: "graphsRanges.top_centers",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "most_validations",
        variant: "collection_centers",
      },
      {
        title: "Puntos Verdes con más validaciones (TOP 10)",
        subtitle: undefined,
        nameFilter: "graphsRanges.top_centers",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "most_validations",
        variant: "green_points",
      },
      {
        title:
          "Regla 80-15-5 (Dispersión del total de  Kg en Centros de Acopio)",
        subtitle: undefined,
        nameFilter: "graphsRanges.percents",
        span: 2,
        graphicType: "vertical_bar_comparative",
        name_graphic_data: "percents",
        variant: "collection_centers",
      },
      {
        title: "Regla 80-15-5 (Dispersión del total de  Kg en Puntos Verdes)",
        subtitle: undefined,
        nameFilter: "graphsRanges.percents",
        span: 2,
        graphicType: "vertical_bar_comparative",
        name_graphic_data: "percents",
        variant: "green_points",
      },
      {
        title: "Categorías  con mayor número de validaciones  (TOP 10) ",
        subtitle: undefined,
        nameFilter: "graphsRanges.categories",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "most_residue_waste_categories",
        variant: "collection_centers",
      },
      {
        title: "Categorías  con mayor número de validaciones  (TOP 10)",
        subtitle: undefined,
        nameFilter: "graphsRanges.categories",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "most_residue_waste_categories",
        variant: "green_points",
      },
      {
        title: "Residuos  con mayor número de validaciones  (TOP 10)",
        subtitle: undefined,
        nameFilter: "graphsRanges.waste",
        span: 2,
        graphicType: "horizontal_bar",
        name_graphic_data: "most_residue_wastes",
        variant: "collection_centers",
      },
      {
        title: "Residuos  con mayor número de validaciones  (TOP 10)",
        subtitle: undefined,
        nameFilter: "graphsRanges.waste",
        span: 2,
        graphicType: "horizontal_bar",
        name_graphic_data: "most_residue_wastes",
        variant: "green_points",
      },
      {
        title: "Centros de Acopio vs  Categoría de residuos (TOP 10 )",
        subtitle: undefined,
        nameFilter: "graphsRanges.centers_categories",
        span: 1,
        graphicType: "horizontal_bar_stacked",
        name_graphic_data: "centers_categories",
        variant: "collection_centers",
      },
      {
        title: "Puntos verdes vs  Categoría de residuos (TOP 10 )",
        subtitle: undefined,
        nameFilter: "graphsRanges.centers_categories",
        span: 1,
        graphicType: "horizontal_bar_stacked",
        name_graphic_data: "centers_categories",
        variant: "green_points",
      },
      {
        title: "Centros de Acopio vs residuos (TOP 10 )",
        subtitle: undefined,
        nameFilter: "graphsRanges.centers_wastes",
        span: 2,
        graphicType: "horizontal_bar_stacked",
        name_graphic_data: "centers_wastes",
        variant: "collection_centers",
      },
      {
        title: "Puntos verdes vs residuos (TOP 10 )",
        subtitle: undefined,
        nameFilter: "graphsRanges.centers_wastes",
        span: 2,
        graphicType: "horizontal_bar_stacked",
        name_graphic_data: "centers_wastes",
        variant: "green_points",
      },
      {
        title: "Categorías con menor número de validaciones  (TOP 10)",
        subtitle: undefined,
        nameFilter: "graphsRanges.categories_minor",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "minor_residue_waste_categories",
        variant: "collection_centers",
      },
      {
        title: "Categorías con menor número de validaciones  (TOP 10)",
        subtitle: undefined,
        nameFilter: "graphsRanges.categories_minor",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "minor_residue_waste_categories",
        variant: "green_points",
      },
      {
        title: "Residuos con  menor número de validaciones  (TOP 10)",
        subtitle: undefined,
        nameFilter: "graphsRanges.waste_minor",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "minor_residue_waste",
        variant: "collection_centers",
      },
      {
        title: "Residuos con  menor número de validaciones  (TOP 10)",
        subtitle: undefined,
        nameFilter: "graphsRanges.waste_minor",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "minor_residue_waste",
        variant: "green_points",
      },
    ],
    collection_centers: [
      {
        title: "Ubicaciones con más validaciones (TOP 10)",
        subtitle: "*Estado",
        nameFilter: "graphsRanges.location_state",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "states",
        variant: "collection_centers",
      },
      {
        title: "Ubicaciones con más validaciones (TOP 10)",
        subtitle: "*Municipio",
        nameFilter: "graphsRanges.location_town",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "towns",
        variant: "collection_centers",
      },
      {
        title: "Centros de Acopio con más Validaciones (TOP 10) ",
        subtitle: undefined,
        nameFilter: "graphsRanges.top_centers",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "most_validations",
        variant: "collection_centers",
      },
      {
        title:
          "Regla 80-15-5 (Dispersión del total de  Kg en Centros de Acopio)",
        subtitle: undefined,
        nameFilter: "graphsRanges.percents",
        span: 2,
        graphicType: "vertical_bar_comparative",
        name_graphic_data: "percents",
        variant: "collection_centers",
      },
      {
        title: "Categorías  con mayor número de validaciones  (TOP 10) ",
        subtitle: undefined,
        nameFilter: "graphsRanges.categories",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "most_residue_waste_categories",
        variant: "collection_centers",
      },
      {
        title: "Residuos  con mayor número de validaciones  (TOP 10)",
        subtitle: undefined,
        nameFilter: "graphsRanges.waste",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "most_residue_wastes",
        variant: "collection_centers",
      },
      {
        title: "Centros de Acopio vs  Categoría de residuos (TOP 10 )",
        subtitle: undefined,
        nameFilter: "graphsRanges.center_categories",
        span: 1,
        graphicType: "horizontal_bar_stacked",
        name_graphic_data: "centers_categories",
        variant: "collection_centers",
      },
      {
        title: "Residuos con  menor número de validaciones  (TOP 10)",
        subtitle: undefined,
        nameFilter: "graphsRanges.waste_minor",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "minor_residue_waste",
        variant: "collection_centers",
      },
    ],
    collectors: [
      {
        title: "Ubicaciones con más validaciones (TOP 10)",
        subtitle: "*Estado",
        nameFilter: "graphsRanges.location_state",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "states",
        variant: "collectors",
      },
      {
        title: "Ubicaciones con más validaciones (TOP 10)",
        subtitle: "*Municipio",
        nameFilter: "graphsRanges.location_town",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "towns",
        variant: "collectors",
      },
      {
        title: "Centros de Acopio con más Validaciones (TOP 10) ",
        subtitle: undefined,
        nameFilter: "graphsRanges.top_centers",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "most_validations",
        variant: "collectors",
      },
      {
        title:
          "Regla 80-15-5 (Dispersión del total de  Kg en Centros de Acopio)",
        subtitle: undefined,
        nameFilter: "graphsRanges.percents",
        span: 2,
        graphicType: "vertical_bar_comparative",
        name_graphic_data: "percents",
        variant: "collectors",
      },
      {
        title: "Categorías  con mayor número de validaciones  (TOP 10) ",
        subtitle: undefined,
        nameFilter: "graphsRanges.categories",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "most_residue_waste_categories",
        variant: "collectors",
      },
      {
        title: "Residuos  con mayor número de validaciones  (TOP 10)",
        subtitle: undefined,
        nameFilter: "graphsRanges.waste",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "most_residue_wastes",
        variant: "collectors",
      },
      {
        title: "Centros de Acopio vs  Categoría de residuos (TOP 10 )",
        subtitle: undefined,
        nameFilter: "graphsRanges.centers_categories",
        span: 1,
        graphicType: "horizontal_bar_stacked",
        name_graphic_data: "centers_categories",
        variant: "collectors",
      },
      {
        title: "Residuos con  menor número de validaciones  (TOP 10)",
        subtitle: undefined,
        nameFilter: "graphsRanges.waste_minor",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "minor_residue_waste",
        variant: "collectors",
      },
    ],
    green_points: [
      {
        title: "Ubicaciones con más validaciones (TOP 10)",
        subtitle: "*Estado",
        nameFilter: "graphsRanges.location_state",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "states",
        variant: "green_points",
      },
      {
        title: "Ubicaciones con más validaciones (TOP 10)",
        subtitle: "*Municipio",
        nameFilter: "graphsRanges.location_town",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "towns",
        variant: "green_points",
      },
      {
        title: "Centros de Acopio con más Validaciones (TOP 10) ",
        subtitle: undefined,
        nameFilter: "graphsRanges.top_centers",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "most_validations",
        variant: "green_points",
      },
      {
        title:
          "Regla 80-15-5 (Dispersión del total de  Kg en Centros de Acopio)",
        subtitle: undefined,
        nameFilter: "graphsRanges.percents",
        span: 2,
        graphicType: "vertical_bar_comparative",
        name_graphic_data: "percents",
        variant: "green_points",
      },
      {
        title: "Categorías  con mayor número de validaciones  (TOP 10) ",
        subtitle: undefined,
        nameFilter: "graphsRanges.categories",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "most_residue_waste_categories",
        variant: "green_points",
      },
      {
        title: "Residuos  con mayor número de validaciones  (TOP 10)",
        subtitle: undefined,
        nameFilter: "graphsRanges.waste",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "most_residue_wastes",
        variant: "green_points",
      },
      {
        title: "Centros de Acopio vs  Categoría de residuos (TOP 10 )",
        subtitle: undefined,
        nameFilter: "graphsRanges.centers_categories",
        span: 1,
        graphicType: "horizontal_bar_stacked",
        name_graphic_data: "centers_categories",
        variant: "green_points",
      },
      {
        title: "Residuos con  menor número de validaciones  (TOP 10)",
        subtitle: undefined,
        nameFilter: "graphsRanges.waste_minor",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "minor_residue_waste",
        variant: "green_points",
      },
    ],
  };

  function selecDataStats({
    variant,
    name_graphic_data,
    graphicType,
  }: {
    variant?: string;
    name_graphic_data: string;
    graphicType?: string;
  }) {
    const stats =
      variant != "green_points" ? stats_collection_centers : stats_collectors;

    return stats
      ? name_graphic_data === "percents"
        ? stats[name_graphic_data]
        : (generateGraphic(
            stats as any,
            name_graphic_data,
            name_graphic_data == "validation_center_types" ? true : false,
            graphicType == "horizontal_bar_stacked"
          ) as any)
      : undefined;
  }

  // useCheckRefetch(refetchCards, global, ranges, years);
  useCheckRefetch(refetch_collection_centers, global, ranges, years);
  useCheckRefetch(refetch_collectors, global_gp, ranges_gp, years_gp);

  return (
    <TabsContent value="Activity">
      {/* <div className="flex w-full  flex-wrap items-center justify-around px-5 py-3  gap-4">
        <div className="w-full flex justify-end px-5 items-center">
          <FilterPerYearController name={"globalRange"} control={control} />
        </div>
      </div> */}
      <div className="grid grid-cols-2 px-5 py-2 gap-4 my-14">
        {dataGraphics_1.map((item, idx) => (
          <WasteValidationsContainer
            key={`graphicCell_${idx * Math.random() * 100}`}
            className={`col-span-2 bg-admins-centers bg-opacity-15`}
            title={"Historial de validaciones por tipo de centro"}
            filter={
              <FilterPerYearController
                name={"graphsYear.year_filter"}
                control={control}
              />
            }
          >
            <OverviewItem
              graphicType={"line"}
              data={
                generateGraphic(
                  stats_collection_centers as any,
                  "validation_center_types",
                  false,
                  true
                ) as any
              }
            />
          </WasteValidationsContainer>
        ))}
        <div className="col-span-2 grid grid-cols-3 gap-4 py-5">
          {/* Control tipo analisis */}
          <div className="col-span-3 flex flex-row space-x-3 justify-end">
            <Controller
              name={"type_collection_center"}
              control={control_centers}
              render={({ field: { name, onChange, value } }) => (
                <CheckBoxGroup
                  options={[
                    {
                      text_label: "Análisis Global",
                      slug: "global",
                    },
                    {
                      text_label: "Centros de Acopio",
                      slug: "collection_centers",
                    },
                    {
                      text_label: "Puntos verdes",
                      slug: "green_points",
                    },
                    {
                      text_label: "Recolectores",
                      slug: "collectors",
                    },
                  ]}
                  value={value}
                  setValue={onChange}
                />
              )}
            />
          </div>
          {/* Card total de centros que validan y no validan */}
          {(type_collection_center == "global" ||
            type_collection_center == "collection_centers") && (
            <div className="col-span-3 flex justify-around">
              <CardGradientData
                variant={"whiteDisabled"}
                icon_to_show={
                  <FontAwesomeIcon
                    className="text-admins-text-active fa-xl aspect-square"
                    icon={faPeopleGroup}
                  />
                }
                className="w-64 h-52 shadow-md aspect-square"
                scans_number={cards?.ticket}
                text_card="Validan"
                showDownloadIcon={false}
              />
              <CardGradientData
                variant={"whiteDisabled"}
                icon_to_show={
                  <FontAwesomeIcon
                    className="text-admins-text-active fa-xl aspect-square"
                    icon={faPeopleGroup}
                  />
                }
                className="w-64 h-52 shadow-md aspect-square"
                scans_number={cards?.no_ticket}
                text_card="No Validan"
                showDownloadIcon={false}
              />
            </div>
          )}
          {/*Indicadores de centros de acopio, puntos verdes y recolectores*/}
          <div className="col-span-3 flex flex-row space-x-3 justify-start">
            <div className="flex flex-row items-center">
              <Dot size={64} className="text-admins-collection_centers" />
              <p>Centros de acopio</p>
            </div>
            <div className="flex flex-row items-center">
              <Dot size={64} className="text-admins-green_points" />
              <p>Puntos verdes</p>
            </div>
            <div className="flex flex-row items-center">
              <Dot size={64} className="text-admins-collectors" />
              <p>Recolectores</p>
            </div>
          </div>
          {/* Card total de kilos reciclados */}
          <WasteValidationsContainer
            className={``}
            title={"Kilos/toneladas  totales reciclados"}
            filter={
              <FilterPerYearController
                name={"graphsRanges.total_kilograms"}
                control={control}
              />
            }
          >
            {(type_collection_center == "global" ||
              type_collection_center == "collection_centers") && (
              <CardNumOverview
                variant={"collection_centers"}
                className="w-1/2 aspect-square items-center"
                number={
                  cards?.stats?.centro_acopio
                    ? cards?.stats?.centro_acopio?.total_kilograms > 1000
                      ? (
                          cards?.stats?.centro_acopio?.total_kilograms / 1000
                        ).toFixed(2) + " ton"
                      : cards?.stats?.centro_acopio?.total_kilograms
                    : 0
                }
              />
            )}
            {(type_collection_center == "global" ||
              type_collection_center == "green_points") && (
              <CardNumOverview
                variant={"green_points"}
                className="w-1/2 aspect-square items-center"
                number={
                  cards?.stats?.punto_verde
                    ? cards?.stats?.punto_verde?.total_kilograms > 1000
                      ? (
                          cards?.stats?.punto_verde?.total_kilograms / 1000
                        ).toFixed(2) + " ton"
                      : cards?.stats?.punto_verde?.total_kilograms
                    : 0
                }
              />
            )}
            {type_collection_center == "collectors" && (
              <CardNumOverview
                variant={"collectors"}
                className="w-1/2 aspect-square items-center"
                number={
                  cards?.stats?.recolector
                    ? cards?.stats?.recolector?.total_kilograms > 1000
                      ? (
                          cards?.stats?.recolector?.total_kilograms / 1000
                        ).toFixed(2) + " ton"
                      : cards?.stats?.recolector?.total_kilograms
                    : 0
                }
              />
            )}
          </WasteValidationsContainer>
          {/* Card promedio de validaciones por centro de acopio */}
          <WasteValidationsContainer
            className={``}
            title={"Promedio de validaciones por centro de acopio"}
            filter={
              <FilterPerYearController
                name={"graphsRanges.average_validations"}
                control={control}
              />
            }
          >
            {(type_collection_center == "global" ||
              type_collection_center == "collection_centers") && (
              <CardNumOverview
                variant={"collection_centers"}
                className="w-1/2 aspect-square items-center"
                number={
                  cards?.stats?.centro_acopio?.average_validations
                    ? cards?.stats?.centro_acopio?.average_validations.toFixed(
                        2
                      )
                    : 0
                }
              />
            )}
            {(type_collection_center == "global" ||
              type_collection_center == "green_points") && (
              <CardNumOverview
                variant={"green_points"}
                className="w-1/2 aspect-square items-center"
                number={
                  cards?.stats?.punto_verde?.average_validations
                    ? cards?.stats?.punto_verde?.average_validations.toFixed(2)
                    : 0
                }
              />
            )}
            {type_collection_center == "collectors" && (
              <CardNumOverview
                variant={"collectors"}
                className="w-1/2 aspect-square items-center"
                number={
                  cards?.stats?.recolector?.average_validations
                    ? cards?.stats?.recolector?.average_validations.toFixed(2)
                    : 0
                }
              />
            )}
          </WasteValidationsContainer>
          {/* Card promedio de Kg por validación */}
          <WasteValidationsContainer
            className={``}
            title={"Promedio de Kg por validación"}
            filter={
              <FilterPerYearController
                name={"graphsRanges.average_kilograms"}
                control={control}
              />
            }
          >
            {(type_collection_center == "global" ||
              type_collection_center == "collection_centers") && (
              <CardNumOverview
                variant={"collection_centers"}
                className="w-1/2 aspect-square items-center"
                number={
                  cards?.stats?.centro_acopio?.average_kilograms
                    ? cards?.stats?.centro_acopio?.average_kilograms.toFixed(2)
                    : 0
                }
              />
            )}
            {(type_collection_center == "global" ||
              type_collection_center == "green_points") && (
              <CardNumOverview
                variant={"green_points"}
                className="w-1/2 aspect-square items-center"
                number={
                  cards?.stats?.centro_acopio?.average_kilograms
                    ? cards?.stats?.centro_acopio?.average_kilograms.toFixed(2)
                    : 0
                }
              />
            )}
            {type_collection_center == "collectors" && (
              <CardNumOverview
                variant={"collectors"}
                className="w-1/2 aspect-square items-center"
                number={
                  cards?.stats?.recolector?.average_kilograms
                    ? cards?.stats?.recolector?.average_kilograms.toFixed(2)
                    : 0
                }
              />
            )}
          </WasteValidationsContainer>
        </div>
        {dataPerCollectionCenter[type_collection_center]?.map((item, idx) => (
          <WasteValidationsContainer
            key={`graphicCell_${idx * Math.random() * 100}`}
            className={`${item.span == 1 ? "" : "col-span-2"}`}
            variant={item.variant}
            title={item.title}
            subtitle={item.subtitle}
            filter={
              <FilterPerYearController
                name={item.nameFilter}
                control={
                  type_collection_center == "green_points"
                    ? control_gp
                    : control
                }
              />
            }
          >
            <OverviewItem
              graphicType={item.graphicType}
              data={selecDataStats({
                graphicType: item.graphicType,
                name_graphic_data: item.name_graphic_data,
                variant: item.variant,
              })}
            />
          </WasteValidationsContainer>
        ))}
        {/* Cards promedio de ratings por centro de acopio */}
        <WasteValidationsContainer
          variant={"collection_centers"}
          title={"Calificación promedio"}
        >
          <div className="flex flex-wrap w-2/3 h-full items-center justify-around min-h-32">
            <p className=" text-admins-text-active text-5xl items-center font-extrabold">
              {Number(
                (type_collection_center === "collection_centers" ||
                type_collection_center === "global"
                  ? cards?.stats?.centro_acopio?.score
                  : type_collection_center === "green_points"
                  ? cards?.stats?.punto_verde?.score
                  : type_collection_center === "collectors" &&
                    cards?.stats?.recolector?.score) ?? 0
              ).toFixed(2)}
            </p>
            <Separator
              orientation="vertical"
              className="bg-admins-text-active w-1"
            />
            <FontAwesomeIcon
              className="text-admins-text-active fa-2xl"
              icon={faStar}
            />
          </div>
        </WasteValidationsContainer>
        {type_collection_center == "global" && (
          <WasteValidationsContainer
            variant={"green_points"}
            title={"Calificación promedio"}
          >
            <div className="flex flex-wrap w-2/3 h-full items-center justify-around min-h-32">
              <p className=" text-admins-text-active text-5xl items-center font-extrabold">
                {Number(cards?.stats?.punto_verde?.score ?? 0).toFixed(2)}
              </p>
              <Separator
                orientation="vertical"
                className="bg-admins-text-active w-1"
              />
              <FontAwesomeIcon
                className="text-admins-text-active fa-2xl"
                icon={faStar}
              />
            </div>
          </WasteValidationsContainer>
        )}
      </div>
    </TabsContent>
  );
};
