import { AdminLayout } from "@/components";
import { Tabs, TabsList, TabsTrigger } from "@/components/atoms/shadcn/ui/tabs";
import { ProductActivity, ProductDemographics } from "@/components/organisms";
import { dataServices } from "@/hooks/dataServices";
import { FetcherOverviewData } from "@/interfaces";
import {
  formatData,
  formatDataComplexNew,
  formatParamsForFilters,
} from "@/shared/functions/formData";
import { useSession } from "next-auth/react";
import React, { useState } from "react";

function ProductsOverview() {
  const { data: session } = useSession();
  const [activeTab, setActiveTab] = useState("Activity");
  return (
    <Tabs value={activeTab} onValueChange={setActiveTab}>
      <div className="flex w-full  flex-wrap items-center justify-around px-5 py-5  gap-4 mt-44">
        <div className="w-full flex justify-end px-5 items-end">
          <div className="flex flex-col space-y-9 h-full  items-end w-fit">
            <TabsList className="w-full h-14 justify-between rounded-2xl px-4 space-x-5">
              <TabsTrigger
                variant={"adminsTab"}
                className="w-1/2"
                value="Activity"
              >
                Actividad
              </TabsTrigger>
              <TabsTrigger
                variant={"adminsTab"}
                className="w-1/2"
                value="Demographics"
              >
                Demográficos
              </TabsTrigger>
            </TabsList>
          </div>
        </div>
      </div>
      <ProductActivity session={session} isActive={activeTab === "Activity"} />
      <ProductDemographics
        session={session}
        isActive={activeTab === "Demographics"}
      />
    </Tabs>
  );
}

export default ProductsOverview;

ProductsOverview.getLayout = function getLayout(page: React.ReactNode) {
  return <AdminLayout>{page}</AdminLayout>;
};

export async function fetcherOverviewDataProductRegistrations({
  token,
  ranges,
  global,
  years,
}: FetcherOverviewData) {
  const { localRanges, localYears } = formatParamsForFilters(
    ranges,
    global,
    years
  );
  const response = await dataServices({
    url: "/product_registrations/overview",
    token,
    params: {
      filters: {
        ...localRanges,
        ...localYears,
      },
    },
  });
  return {
    ...formatData(response.data),
    ...formatDataComplexNew(response.data, "history"),
  };
}

export async function fetcherOverviewDemographicsProductRegistrations({
  token,
  ranges,
  global,
  years,
}: FetcherOverviewData) {
  const { localRanges } = formatParamsForFilters(ranges, global, years);
  const response = await dataServices({
    url: "/product_registrations/demographics_stats",
    token,
    params: {
      filters: {
        ...localRanges,
      },
    },
  });
  return {
    ...formatData(response.data),
  };
}
